<template>
  <div class="cpt-mount cpt-MMC_Gimbal_S1">
    <div class="bd">
      <div class="form-wrap mount-form">
        <div class="form-item">
          <div class="label-box">预设重量</div>
          <div class="input-box">
            <div class="text-box">{{ height }} 克</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态
          </div>
          <div class="input-box">
            <div class="text-box">正常</div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">智能模式</div>
          <div class="input-box">
            <el-switch
              @change="handle_change_lntelligent_mode_ctrl"
              v-model="lntelligent_mode_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">执行动作</div>
          <div class="input-box">
            <el-switch
              @change="handle_change_motor_weight"
              v-model="motor_weight_status"
              size="mini"
            ></el-switch>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">重量（g）</div>
          <div class="input-box">
            <el-input
              v-model="pre_height"
              size="mini"
              placeholder="请输入重量"
            />
            <el-button @click="height = pre_height" size="mini" type="primary"
              >预设</el-button
            >
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">俯仰操作</div>
        </div>
        <div class="rocker-box">
          <div class="rocker" ref="rocker"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nipplejs from "nipplejs";
import { MMC_Gimbal_S1 } from "../utils";
export default {
  props: {
    whachChange:{
      typeof:Number,
      default:0
    }
  },
  data() {
    return {
      height: 300,
      pre_height: 300,
      lntelligent_mode_status: false,
      motor_weight_status: false,
      joystick:null
    };
  },
  methods: {
    handle_change_lntelligent_mode_ctrl(value) {
      let buffer = MMC_Gimbal_S1.lntelligent_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_change_motor_weight(value) {
      let buffer = MMC_Gimbal_S1.motor_weight(value);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },

    init_rocker() {
      this.joystick = nipplejs.create({
        zone: this.$refs["rocker"],
        mode: "static",
        position: {
          left: "50%",
          top: "50%",
        },
        color: "#9bacbe",
      });

      this.joystick
        .on("start", this.start_timer)
        .on("end", this.stop_timer)
        .on(
          "dir:up plain:up dir:left plain:left dir:down plain:down dir:right plain:right",
          (evt, data) => {
            const dir = data.direction.angle;
            switch (dir) {
              case "up":
                this.buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(
                  1,
                  this.control_speed
                );
                break;
              // case "right":
              //   this.buffer = MMC_Gimbal_S1.gimbal_yaw_ctrl(
              //     1,
              //     this.control_speed
              //   );
              //   break;
              // case "left":
              //   this.buffer = MMC_Gimbal_S1.gimbal_yaw_ctrl(
              //     -1,
              //     this.control_speed
              //   );
              //   break;
              case "down":
                this.buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(
                  -1,
                  this.control_speed
                );
            }
          }
        );
    },
    start_timer() {
      this.buffer = null;
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.buffer) {
            this.commit_directive(this.buffer);
          }
        }, 200);
      }
    },
    stop_timer() {
      this.buffer = MMC_Gimbal_S1.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(this.buffer);

      if (this.buffer) {
        this.buffer = null;
      }
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
    },
  },
  watch:{
    whachChange(){
      if(this.joystick){
        this.joystick.destroy()
      }
      this.init_rocker()
    }
  },
  mounted() {
    this.$emit("width", '416px')
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  beforeDestroy() {
    this.stop_timer();
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/index.scss";
</style>